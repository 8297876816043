.client-list-page {
  padding: 20px;
  width: 450px;

  .client-button-container {
    .label-container {
      display: flex;
      align-items: center;
    }
  }
  .client-groups-grid {
    .dx-toolbar .dx-toolbar-items-container {
      margin-left: -15px;
    }
  }
}
