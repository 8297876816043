/** Override the modal style sheet*/
#modal-content-container {
  #modal-main-content {
    flex: 1 1 auto;
    margin-bottom: 10px;
    border-bottom: 0px solid !important;
  }
}

/** Override validation error message*/
.dx-field-item-required-mark {
  color: #c9302c;
}
.dx-texteditor-container {
  display: -ms-flexbox;
  display: flex;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border: 1px solid rgb(179, 175, 175);
  border-radius: 4px;
  border-bottom: 1px solid white !important;
}
.modal-title-font {
  font-size: '1.5em';
}
.modal-title-alignment {
  font-size: '1em';
  margin-right: '10px';
  vertical-align: 'text-top';
}
