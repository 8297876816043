.state-manager-banner {
  display: flex;

  banner-container-wrapper .banner-header-full-width {
    width: 60%;
  }

  .current-view-control{
    margin-left: -58px;
    margin-right: 20px;
    max-width: 250px;
  }

  .tax-year-dropdown {
    max-width: 200px;
  }

  .cutoff-date {
    min-width: 100px;
    display: flex;
    justify-self: start;
    flex-direction: column;
    /* Stack items vertically */

    .date {
      font-size: 18px;
      /* Adjust the font size as needed */
      margin-left: 25px;
    }

    .title {
      font-size: 14px;
      /* Adjust the font size as needed */
      margin-top: 4px;
      /* Add some spacing between date and title */
      margin-left: 25px;
    }
  }

}