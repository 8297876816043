.cui-c_table {
  //
  &-actions {
    button{
      margin-left: $layout-gap;
      span{
        margin-right:$layout-gap;
      }
    }
  }
  &-heading {
    color: var(--cui-color-primary);
    font-size:1rem;
  }
  //
  &-dx {
    .dx-datagrid-group-closed,
    .dx-datagrid-group-opened {
      color: inherit;
    }
    .dx-datagrid-header-panel {
      padding: 0;
      margin-bottom: $layout-gap;
    }

    &_tile {
        .dx-group-row {
            display:none;
        }
        .dx-datagrid-header-panel {
            border:0;
        }
        .dx-datagrid-rowsview {
            margin: 0 -12px;
            border:0;
        }  
        //Apply tile margins when placed in a DX Tile Template
        .cui-c_tile {
            margin: 12px;
        }      
        table {
            display:block;
            margin-left:-24px;
            margin-right:-24px;
            display:flex;
            flex-wrap: wrap;
            colgroup {
                display:none;
            }
        }    
        tbody {
            display:none;
        }    
      tbody.dx-data-row {
        display: block;
        //width: 33%;
        float: left;
        flex-grow:1;
          @include respond-to('xs') {
            flex: 0 0 100%;
          }
          @include respond-to('sm') {
            flex: 0 0 calc(100% / 2);
          }
          @include respond-to('lg') {
            flex: 0 0 calc(100% / 3);
          }  
          @include respond-to('xl') {
            flex: 0 0 calc(100% / 4);
          }  
        tr,
        td {
            display:block;
            padding:0 !important;
        }        
      }
    }
  }
}
