.general-tab-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .content-container {
    display: flex;
    min-height: 50vh;

    .left-container {
      margin-top: -10px;
      margin-left:2px;
      width: 50%;
      padding: -10px;
    }

    .right-container {
      margin-bottom: -20px;
      margin-top: -5px;
      width: 60%;
      align-items: flex-end;
      padding-right: 15px;
      padding-bottom: 2px;
      .dx-calendar-body table {
        display: table-row-group;
      }
    }
  }
}
.general-tab-buttons {
  margin-top: 4px;
  float: right;
  align-items: flex-end;

  .save-btn-State {
    margin-left: 5px;
  }
}