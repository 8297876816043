.entity-selector {
  max-width: 99%;
  max-height: 80%;
  margin-top: 2px;
  margin-bottom: 2px;

  .entity-selector-datagrid {
    margin-bottom: 4px;
    height: 80%;
    max-width: 100%;

    .dx-datagrid-header-panel {
      margin-right: -9px;
      border: 1px solid #e7e7e8;
      padding: 0;
      margin: 0;
      border-bottom: 0;
    }
  }

  .select-all-chkbox {
    padding-top: 7px;
    padding-left: 8px;

    .checkbox-container {
      display: flex;
      align-items: center;
      padding-left: 8px;

      .select-all-chkbox-text {
        margin-left: 28px;
      }
    }
  }
}