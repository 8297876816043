@import '../../ui-kit/sass/abstracts/variables';

.file-upload-control {
    .file-upload-control-container {
        display: flex;
        flex-direction: column;

        align-items: center;
        justify-content: center;

        width: 100%;
        height: auto;
        margin-top: 0px;
        margin-bottom: 0px;
        padding: 2em;
        background-color: rgb(248, 248, 248);
        border: rgb(216, 216, 216) dashed 1px;


        .file-acceptance-message {
            color: rgb(150, 150, 150);
            margin-top: .5em;
        }

    }

    .padded {
        padding: 1em;
    }

    .with-margined {
        margin: 1em;
    }

    .rounded-icon {
        display: flex;

        align-items: center;
        justify-content: center;

        color: $cui-color-ico-base;

        $bg-size: 32px;
        width: $bg-size;
        height: $bg-size;
        border-radius: $bg-size / 2;
        background-color: rgb(209, 231, 231);
    }

    .upload-click-text {
        margin-right: 0.25em;

    &:not(.disabled) {
            color: $cui-color-ico-base;
            text-decoration-line: underline;
            cursor: pointer;
        }
    }

    #file-input {
        display: none;
    }

    .file-selection-box {
        @extend .padded;

        .file-selection-box-container {
            width: 100%;
            display: flex;
            flex-direction: row;

            .column-1 {
                @extend .padded;
            }

            .column-2 {
                @extend .padded;
                flex: 1 1 auto;

                >* {
                    margin-bottom: .5em;
                }

                .file-size-and-state {
                    color: rgb(150, 150, 150);
                    font-weight: lighter;
                }
            }

            .column-3 {
                @extend .padded;
            }

            .clear-button:not(.disabled) {
                cursor: pointer;
            }
        }

        .progress-container {
            @extend .padded;

            width: 100%;
        }
    }

    .disabled {
        color: rgb(150, 150, 150);
    }
}