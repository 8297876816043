
#cui-layout_leftnav {
    width: $layout-dx;
    position:fixed;
    z-index:11;
    background: var(--cui-color-bg-light);
    .nav-name {
        display:none;
    }

    &.nav-expanded {
        width: $layout-menu-active;
        .nav-name {
            display:block;
        }
        nav {
            .nav-list_lvl-two {
                padding-left:$layout-dx;
            }
        }
    }

    &.nav-collapsed {
        nav {
            
            > ul > li:hover {
                > a {
                    background-color:var(--cui-color-bg-base);
                    z-index: 2;
                    position: relative;
                }
                > ul {                    
                    visibility: visible;
                    box-shadow: 0 0 15px var(--cui-color-shadow-base);
                    clip-path: inset(0px -15px 0px 0px);
                    opacity: 1;
                }
            }
            > ul > li > ul {
                box-sizing: border-box;
                padding:$layout-gap * 2;
                > li:first-child {
                    margin-top: $layout-dx;
                    // padding-top: $layout-gap*2;
                    // border-top:1px solid #ccc;
                }
            }
            .nav-list_lvl-two::before {
                content: attr(title);
                color: var(--cui-color-primary);                
                width:100%;
                border-bottom:1px solid var(--cui-color-border-dark);
                position:absolute;
                top:0;
                left:$layout-gap*2;
                width: calc(100% - $layout-gap*4);
                line-height: $layout-dx;
            }
            > ul > li > ul {
                opacity: 0;
                transition: opacity 200ms ease-in;
                visibility:hidden;
            }
            > ul > li > ul {
                background-color:var(--cui-color-bg-base);
                position:absolute;
                left: 56px;
                height:100vh;
                overflow-y:auto;
                width:250px;
                top:0;
                z-index:1;
            }
        }
    }
    .cui-nav {
        position:relative;
        z-index:20;
        nav,
        menu,
        ul,
        li {
            padding:0;
            margin:0;
            list-style:none;
        }

        nav{
            height: calc(100% - #{$layout-dx * 2});
            overflow-y: auto;
    
            a{
                color: rgba(var(--cui-color-base-rgb), .65);
                transition: color 200ms linear;
                &:hover {
                    color: rgba(var(--cui-color-base-rgb), 1)
                }
            }        
        }
        menu {
            height: $layout-dx *2;
            background: var(--cui-color-primary);
            flex-direction:column;                
            box-shadow: 0 0 15px var(--cui-color-shadow-base);
            clip-path: inset(0px 0px -15px 0px);
            a {
                &:not(:last-child)::after {
                    left: $layout-gap;
                    position:absolute;
                    content:'';
                    width:calc(100% - #{$layout-gap}*2);
                    height:1px;
                    border-bottom:1px solid transparentize(#fff, .50);
                    bottom:0;
                }
            }
            
        }
        
        a {
            cursor:pointer;
            text-decoration:none;            
            color:#fff;
            //width: $layout-dx;
            height: $layout-dx;
            display:flex;
            align-items:center;
            //justify-content: center;
            position:relative;
            span[class*="icons"] {
                width: $layout-dx;
                text-align:center;
            }
            .nav-name {
                font-size:.85rem;
            }
        }

        .nav-list_lvl-two {
            > li:not(:first-child) {
                margin-top: $layout-gap;
            }
            li > span {
                font-size:.85rem;
                display:block;
                color: var(--cui-color-primary);
                padding: 0 $layout-gap $layout-gap 0;
            }
            li > a {
                font-size:.85rem;
                height:auto;
                display:block;
                // padding: math.div($layout-gap, 2) math.div($layout-gap, 2) math.div($layout-gap, 2) 0;
            } 
        }
    
    } 
}