.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.content-container {
  padding: 5px;
}

.content-center {
  text-align: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.no-overflow-x {
  overflow-x: hidden;
}

.no-overflow-y {
  overflow-y: hidden;
  // This is supposed to prevent a horizontal scrollbar from displaying
  //  but this hasn't been tested completely.
  padding-right: 15px;
}

.vertical-flex {
  display: flex;
  flex-direction: column;
}

.flex-fill-own-height {
  flex: 1 1 auto;
}

.flex-fill {
  @extend .flex-fill-own-height;
  height: auto !important;
}

.item-multiple-download-button {
  .dx-state-disabled {
    border-color: black;
  }

  .dx-state-disabled>.dx-button-content>.dx-icon-download {
    color: black;
  }

  .dx-state-disabled>.dx-button-content>.dx-button-text {
    color: black;
  }
}

.section-title {
  font-weight: bold;
  font-size: 1.13em;
}

/*
  CSS for creating a tooltip-style for clickable link for string.

  - Class: .tooltip-string-link
    - Sets the text color to #551A8B (purple).
    - When applied to a string, to create a link with a tooltip-style pop-up effect.
*/
.tooltip-string-link {
  color: #551a8b;
  text-decoration-line: underline;
}