.cui-page {
    
    background:var(--cui-color-bg-base);
    flex-grow: 1;
    padding-bottom: $layout-gap * 2;    
    &_wrapper {
        overflow:hidden;
        position:relative;
        background-color: #fff;
    }
}
//Modifier
.layout-expanded,
.layout-collapsed {
    .cui-page {
        border-top: 10px solid var(--cui-color-primary);
    }
    .cui-page_wrapper {
        top:-10px;
        border-top-left-radius: 10px;
    }
}