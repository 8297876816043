.investor-files-container {
    padding-top: 15px;

    .status-button-container {
        max-width: 40px
    }

    .status-label-container {
        margin-top: 5.7px
    }

    .dx-light-pages {
        display: none !important
    }

    .download-file-button {
        text-transform: none;

        .dx-button-content {
            text-decoration: underline;
        }
    }
    
    .document-count-style {
        padding-top: 5px;
        font-size: 13px;
        padding-left: 20px;
    }

    .show-back-arrow{
        display: block;
    }

    .hide-back-arrow{
        display: none;
    }

}