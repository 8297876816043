.investor-selector-container {
  max-width: 99%;
  max-height: 80%;
  margin-top: 2px;
  margin-bottom: 2px;
  overflow-x: hidden;

  .investorselector-datagrid {
    margin-bottom: 4px;
    height: 80%;
    max-width: 100%;

    .dx-datagrid-header-panel {
      margin-right: -9px;
    }
  }

  .dx-checkbox.dx-state-disabled {
    border: 1px solid rgba(0, 0, 0, 0.26) !important;
  }

  .dx-checkbox.dx-state-readonly .dx-checkbox-icon {
    border: 1px solid rgba(0, 0, 0, 0.26) !important;
  }

  .dx-widget.dx-checkbox.dx-state-disabled {
    border: 1px rgba(0, 0, 0, 0.66) !important;
  }

  .investor-footer {
    .investor-text {
      margin-left: 15px;
      margin-top: 5px;
      font-size: 14px;
      vertical-align: sub;
    }

    .investors-count {
      margin-left: 15px;
      margin-top: 5px;
      border: 1px solid #009cde;
      font-size: 12px;
      padding: 3px;
      vertical-align: sub
    }

    .investor-selector-buttons {
      margin-top: 4px;
      float: right;

      .dx-widget.dx-button.dx-button-mode-contained.dx-button-default.dx-button-has-text.clear-btn-investor.dx-state-disabled {
        opacity: 2.56;
      }

      .save-btn-investor {
        margin-left: 5px;
      }
    }
  }

  .select-all-chkbox {
    padding-top: 7px;
    margin-left: -5px;

    .checkbox-container {
      display: flex;
      align-items: center;
      padding-left: 8px;

      .select-all-chkbox-text {
        margin-left: 20px;
      }
    }
  }

  .dx-toolbar-after .dx-toolbar-item,
  .dx-toolbar-after .dx-toolbar-item:last-child {
    padding: 0 0 0 0;
  }
}