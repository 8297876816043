.investor-composite-elections {
  .field-label {
    font-size: 16px;
    cursor: default;
    align-self: center;
    padding-right: 12px;
    padding-left: 12px;
    padding-top: 8px;
  }

  .align-icon {
    padding-left: 5px;
  }

  .dx-tag-remove-button::after {
    display: none;
  }
}