.investor-landing-page {
  .tile-placeholder-image {
    font-size: 120px;
    color: #dedede;
  }

  .align-center {
    text-align: center;
    display: block;
  }

  .placeholder-align-center {
    text-align: center;
  }

  .button-learn {
    display: none;
  }
  .favorite-icon {
    cursor: pointer;
    color: #009cde;
  }
  .button-align {
    font-size: 12px;
    left: -10px;
  }
}
