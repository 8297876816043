.investor-submission-overview-container {
    margin-top: 5px;
    margin-left: 8px;

    .button-grid {
        display: flex;
        float: right;
        padding-right: 56px;
        padding-top: 4px;
    }

    .contect-administration-button {
        padding-left: 15px;
    }

    .back-button {
        padding-top: 15px;
        padding-left: 10px
    }

    .state-overview-text {
        font-size: 19px;
        padding-left: 10px;
    }

    .sub-header-text {
        font-size: 19px;
        padding-left: 10px;
        margin-bottom: 10px;
    }

    .page-header-items {
        display: flex;
    }

    .sub-header-grid {
        padding-bottom: 10px;
    }
}