.link-investor-modal {
    .preview-mail-btn {
        margin-right: 119px;
        width: 144px
    }

    .cancel-btn {
        width: 95px;
        margin-right: 4px;
    }

    .delete-cust-btn {
        width: 210px;
        margin-right: 4px;
    }

    .save-btn {
        width: 90px;
    }

    .required:after {
        content: " *";
        color: red;
    }

    .investor-link {
        height: 38px;
        float: left;
        color: #009cde;
        padding-right: 435px;
        padding-top: 10px;
        padding-bottom: 9px;
        padding-left: 5px;
    }

    .dx-list-item-content {
        padding: 0px 0px 0px !important;

    }

    .dx-list-item {
        border: 1px solid #DEDEDE;
        margin-bottom: 5px;
    }

    .dx-list-item:hover {
        border: 1px solid #009cde;
    }

    .dx-state-focused {
        border-color: #009cde;
    }

    .dx-list-item-selected{
        border-color: #009cde;
    }

    .content-style {
        margin-bottom: 15px;
    }
}