// NOTE: This is defined in the navWidget.scss file,
//  but since we don't have any containing elements
//  for the navChip, we're using the nav-widget class as the container.
.nav-widget {
  .nav-widget-chip {
    display: flex;
    align-items: center;
  }
  .clickable-chip {
    cursor: pointer;
  }
}
.nav-menu-font {
  font-size: 0.9em !important;
}
