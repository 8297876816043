.add-entity-modal {
  .add-entity-container {
    margin-left: 6px;

    .add-entity-radio {
      margin-top: 10px;

      .dx-item.dx-radiobutton {
        padding-right: 90px;
      }
    }

  }

  .mail-override-container {
    padding-top: 20px;
    padding-left: 5px;

    .mail-override-radio {
      margin-top: 10px;

      .dx-item.dx-radiobutton {
        padding-right: 137px;
      }
    }
  }

  .row-spacing {
    margin-top: 20px;
    padding-left: 7px;
  }

  .cancel-btn {
    margin-right: 15px;
  }

  .label-font {
    font-weight: 700;
  }
}