.quick-edit-container {
  .add-button-align {
    padding-top: 27px;
    padding-left: 0px !important;
  }
  .email-table {
    padding-top: 10px;
  }
  .modal-title {
    font-size: 1em;
    margin-right: 10px;
    vertical-align: text-top;
  }
  .form-scroll-horizontal {
    overflow-x: hidden;
  }
  .dx-field-item-required-mark {
    display: none;
  }
}
/** Override the modal style sheet*/
#modal-content-container {
  #modal-main-content {
    .content-display-align {
      padding-top: 25px;
    }
  }
}
