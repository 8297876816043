.cui-c_dropdown {
    height: $layout-dx;
    width: $layout-dx;
    display:flex;
    align-items:center;
    justify-content: center;
    position:relative;
    &#cui-user_dropdown {
        > span {
            width: 36px;
            height:36px;
            border-radius:50%;
            color:var(--cui-color-primary);
            background-color: var(--cui-color-bg-base);
            line-height:36px;
            font-size: .85em;
            text-align:center;
            text-transform: uppercase;
            position:relative;
            z-index:2;
            border:2px solid rgba(var(--cui-color-primary-rgb), .5);
        }
    }
    //box-shadow: 0 0 5px black;
    //background:#fff;
    > span {
        color:#fff;
    }

    ul{
        max-width:200px;
        margin:0;
        list-style:none;
        padding:0;
        display:none;
        background:var(--cui-color-bg-base);
        position:absolute;
        z-index:1;
        right:0;
        top:$layout-dx;
        min-width:200px;
        a{
            text-decoration:none;
            display: block;
            padding: 0 16px;
            line-height: $layout-dx - 1;
            color:var(--cui-color-secondary);
        }
        li:not(:last-child){
            border-bottom: 1px solid var(--cui-color-border-base);
        }
    }
    &:hover {
        background-color:var(--cui-color-bg-base);
        filter: drop-shadow(0 0 10px var(--cui-color-shadow-base));
        > span {
            color: var(--cui-color-primary);
        }
        ul{
            display:block;
        }
    }
}