.DeleteFilesConfirmation {
  .files-list {
    margin-top: 2em;
    font-weight: bold;
  }
  .modal-title {
    font-size: 1em;
    margin-right: 10px;
    vertical-align: text-top;
  }
}
.delete-title-span {
  font-size: 1em;
  vertical-align: text-top;
}