.state-manager-entity-view {
  .align-header {
    padding-top: 20px;
    padding-left: 15px;
  }
  .align-header-text {
    padding-left: 15px;
  }

  .status-button-container {
    display: flex;
    align-items: center;
    .entity-span {
      padding-left: 15px;
    }
    .counter-display-title {
      padding-left: 15px;
      font-size: 16px;
      .count-border {
        width: 8px;
        height: 8px;
        border: 1px solid #008cd9;
        padding: 2px;
        margin-left: 8px;
      }
    }
  }

  .status-button-container button {
    margin-right: 10px;
  }
  .publish-buttons-complete {
    border-radius: 15px;
    color: #008cd9;
    border: 1px solid #008cd9;
    background-color: #ffff;
  }

  .draft-buttons-incomplete {
    border-radius: 15px;
    color: #b9b9b9;
    border: 1px solid #b9b9b9;
    background-color: #ffff;
  }

  .dx-widget.dx-button.dx-button-mode-contained.dx-button-normal.dx-button-has-icon.dx-revert-button {
    visibility: hidden;
  }

  .disabled-checkbox {
    opacity: 0.9;
  }
}
