@font-face {
    font-family: "Prelo-Black";
    src: local("Prelo-Black"), url(../../assets/fonts/prelo/prelo-black-webfont.woff) format("truetype");
}

@font-face {
    font-family: "Prelo-Bold";
    src: local("Prelo-Bold"), url(../../assets/fonts/prelo/prelo-bold-webfont.woff) format("truetype");
}
@font-face {
    font-family: "Prelo-Bold-Italic";
    src: local("Prelo-Bold-Italic"), url(../../assets/fonts/prelo/prelo-bolditalic-webfont.woff) format("truetype");
}
@font-face {
    font-family: "Prelo-Medium";
    src: local("Prelo-Medium"), url(../../assets/fonts/prelo/prelo-medium-webfont.woff) format("truetype");
}
@font-face {
    font-family: "Prelo-Light";
    src: local("Prelo-Light"), url(../../assets/fonts/prelo/prelo-light-webfont.woff) format("truetype");
}
@font-face {
    font-family: "Prelo-Book";
    src: local("Prelo-Book"), url(../../assets/fonts/prelo/prelo-book-webfont.woff) format("truetype");
}