body,
html,
#root {
    height:100%;
    margin:0;
}

body {
    font-family:arial;
    background: var(--cui-color-bg-dark) !important;
}

#cui-layout {
    height:100%;
    display:flex;
    background: var(--cui-bg-light);
    box-shadow: 0 0 35px var(--cui-color-shadow-base);
    &_main {
        flex:1;
        display:flex;
        flex-direction: column;
        align-items: stretch;
    }      
    &.layout-collapsed {
        #cui-layout_main {
            margin-left:$layout-dx;
        }
    }
    &.layout-expanded {
        #cui-layout_main {
            margin-left:$layout-menu-active;
            
        }
    }
    &.layout-scrolled {
        .cui-header {
            box-shadow: 0 0 15px var(--cui-color-shadow-base);
        }
    }
    &[cui-layout-dx="lg"]{
        margin: 0 auto;
        max-width:1200px;
    }
    &[cui-layout-dx="xl"]{
        margin: 0 auto;
        max-width:1536px;
    }
}


