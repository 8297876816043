.page-footer {
    display:flex;
    align-items: center;
    font-size:.85em;
    background: var(--cui-color-bg-light);
    &_meta {
        margin:$layout-gap*2 0;
        padding-left:$layout-gap * 2;
        flex-grow:1;
        color: var(--cui-color-muted);        
        p{
            margin:0;
            margin-bottom:$layout-gap;
        }

        a{
            color: var(--cui-color-primary);
        }

    }
    &_vid {
        padding-right:$layout-gap * 2;
        .cui-c_app-logo {            
            max-height:36px;
        }
    }
}