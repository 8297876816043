.investor-election-page-container {
    padding-top: 5px;
    padding-left: 15px;

    .back-button {
        padding-top: 15px;
    }

    .state-manager-text {
        font-size: 19px;
        padding-left: 10px;
    }

    .page-header-items {
        display: flex;
    }

    .text-paragraph-scaleing {
        margin: 2;
    }

    .text-paragraph {
        font-size: 16px;
        margin: 0;
    }

    .tab-component {
        padding-top: 10px;
        margin-top: 10px;
    }

    .tab-content {
        height: calc(97vh - 380px);
        justify-content: left;
        display: block;
        padding-right: 56px;
        border-bottom: 2px solid #F1F1F1;
        overflow-y: auto;
    }
       
    .button-grid {
       display: flex;
       float: right;
       padding-top: 4px;
       padding-right: 56px;
       .state-tab-buttons{
           display: flex;
           justify-content: flex-end;
           .cancel-btn-State{
              margin-right: 20px;
                }
            }
        }
        
    .review-and-save-button {
        padding-left: 25px;
    }

    .loading-title {
        text-align: center;
        padding-top: 200px;
    }

    .review-and-submit-button {
        background-color: #009cde;
        box-shadow: #009cde;
        -moz-animation: pulse 1s infinite;
        -webkit-animation: pulse 1s infinite;
        animation: pulse 1s infinite;
    }

    @keyframes pulse {
        0% {
            background-color: #009cde;
        }

        70% {
            background-color: #2cc0ff;
        }

        100% {
            background-color: #68d2ff;
        }
    }

    .tab-icon-align {
        position: absolute;
    }
}