%dx-field-label {
    align-self: initial;
}

.investor-entity-list {
    display: flex;
    flex-direction: column;
    padding: 0;

    .header {
        display: block;
        width: 100%;
        margin-bottom: 20px;
    }

    .dx-field {
        height: 100%;
        display: flex;
    }

    .control-container {
        display: flex;
        flex-direction: row;
        height: 100%;
        overflow-y: hidden;


        >* {
            width: 50%;
        }

        .list {
            display: flex;
            flex-direction: column;
            height: 100%;

            overflow-y: hidden;

            // // To match with the fields (col-6) on the parent control.
            // margin-left: 10px;

            // // This also aligns with the parent control.
            // &:first-child {
            //     margin-left: 0;
            // }

            .dx-field-label {
                @extend %dx-field-label;

            }

            .listing {
                border: 1px solid rgb(179, 175, 175);
                border-radius: 4px;
                display: flex;
                flex-direction: column;
                flex: 1 1 auto;
                width: 60%;
                overflow-y: auto;

                >* {
                    padding: 9px 11px;
                    font-size: 13px;
                }
            }
        }

    }
}