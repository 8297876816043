.add-document-modal {
  .add-file-location-radio {
    margin-top: 4px;
    margin-bottom: 8px;

    .dx-collection>.dx-item.dx-radiobutton {
      padding-right: 90px;
    }
  }

  .padding-top-file-location{
    padding-top: 5px;
  }
  .dx-layout-manager .dx-field-item:not(.dx-first-row) {
    padding-top: 0px;
  }
}