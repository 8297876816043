.investor-withholding-elections {
  .field-label {
    font-size: 16px;
    cursor: default;
    align-self: center;
    padding-right: 12px;
    padding-left: 12px;
    padding-top: 8px;
  }
  .align-icon {
    padding-left: 5px;
  }
  .align-completed-icon {
    position: absolute;
    margin-top: 5px;
  }
  .main {
    display: flex;
    flex-direction: row;
    padding: 5px;
  }
  .firstColumn {
    width: 25%;
    padding: 10px;
  }
  .secondColumn {
    width: 30%;
    padding: 10px;
  }
  .count-text-display {
    padding-left: 10px;
    font-size: x-large;
    color: #009cde;
  }
  .count-text-align {
    padding-left: 5px;
    padding-right: 5px;
  }
  .div-select-box {
    position: relative;
    .select-box-text-align {
      display: inline-block;
      width: 90%;
    }
    .dx-texteditor-container {
      border: 0px;
    }
  }
  .entities-align {
    padding-top: 2px;
  }
  .dx-tag-remove-button::after {
    display: none;
  }
}
