.investor-election-info {
  overflow: hidden;

  .content-area {
    height: calc(92vh - 385px);
    overflow: hidden;
    display: flex;

    .dx-field {
      display: grid;

      .dx-field-label {
        width: initial;
      }
    }

    .dx-texteditor.dx-editor-filled.dx-state-disabled {
      opacity: 1;
    }
  }

  .tab-buttons {
    .save-btn-State {
      margin-left: 5px;
    }
  }
}
