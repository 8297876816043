.add-tax-year-container {
  .form-scroll-horizontal {
    overflow-x: hidden;
  }
  .dx-field-item-required-mark {
    display: none;
  }
  .dx-field-item-content .dx-field-item-content-location-bottom {
    height: 50px;
  }
  .dx-invalid-message.dx-overlay {
    right: 0px !important;
  }
}