.add-client-container {
  .add-client-width {
    width: 400px;
  }

  .add-client-form {
    padding-left: 10px;
    padding-right: 15px;
  }

  .circular-spinner-outer-container {
    width: 100%;

    .circular-spinner-container {
      margin-right: auto;
      margin-left: auto;
      width: 50px;
      margin-top: 15px;
    }
  }

  .MuiFormControlLabel-label {
    font-size: 13px;
  }

  // The disabled MUI checkboxes on this page do not natively
  //  look like the DevExtreme checkboxes, so this syncs them up.
  .dx-state-disabled,
  .Mui-disabled {
    color: #b4b4b4 !important;
    opacity: 1 !important;
    font-size: 13px;

    .dx-checkbox-text {
      opacity: 1 !important;
    }
  }

}