.info-popup {
  display: inline;
  height: 24px;
  align-items: center;

  .popup-text {
    height: 1px;
    > * {
      height: 100%;
    }
  }
  .popup-icon {
    height: 16px;
  }
}
