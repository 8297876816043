@import '../../../common';

.sm-tax-year-control {
    @include annotatedSelectText(annotated-tax-year-select, 'Tax Year:', 200px);

    .tax-year-spacing {
        padding-top: 1px;
        padding-right: 29px;
        padding-left: 8px;

        .dx-dropdowneditor.dx-dropdowneditor-field-clickable,
        .dx-dropdowneditor.dx-dropdowneditor-field-clickable .dx-texteditor-input {
            cursor: pointer;
            border-top-color: currentcolor;
            border-left-color: currentcolor;
            border-right-color: currentcolor;
        }
    }

    .di-tax-year-selectbox-field {
        .di-lock {
            margin-top: 3px;
        }
        display: flex;
        .dx-texteditor-container {
            border: none;
        }
    }
}