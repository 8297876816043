.global-settings-modal {
  .cancel-btn-global {
    width: 120px;
    margin-right: 12px;
    flex-grow: 1;
  }

  .save-btn-global {
    width: 180;
    flex-grow: 1;
  }

  .dx-tab-text {
    // override the css to display tab text as normal (not in Uppercase)
    text-transform: none !important;
  }

  .loading-title {
    font-size: 14px;
    color: #666666;
    text-align: center;
  }

  //styles for global-settings-notification tab
  .email-template-form-container {
    max-width: 99%;

    .add-variable-dropdown {
      padding-top: 22px;
    }

    .dx-toolbar-items-container {
      .dx-size-format {
        border-bottom: 1px solid #B3AFAF;
        border-radius: 5px;
      }
    }

    .save-email-template-button {
      float: right;
    }

    .btn-email-template {
      margin-left: 5px;
    }

    .add-button-align {
      float: right;
    }

    //spacing <p> lines in htmleditor
    .dx-htmleditor-content p {
      margin-bottom: 10px;
    }
  }
}