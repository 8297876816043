.SendEmailConfirmation {
  .email-column-alignment {
    padding-left: 25%;
    margin-right: 2px;
  }

  .email-margin-left {
    margin-left: 5px;
  }
}
