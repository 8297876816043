.submission-complete-page {
  margin-top: 5px;
  margin-left: 18px;

  .back-button {
    margin-top: 8px;
    margin-right: 4px;
  }

  .state-manager-text {
    font-size: 19px;
    padding-left: 2px;
  }

  .page-header-items {
    display: flex;
  }

  .submission-overview {
    margin-right: 40px;
    margin-top: 20px;

    .dx-button-text {
      text-transform: capitalize;
    }
  }

  .contact {
    margin-top: 20px;

    .dx-button-text {
      text-transform: capitalize;
    }
  }

  .sub-header-text {
    font-size: 19px;
    margin-bottom: 10px;
  }
}