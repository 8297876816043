.FormattedDialog {
}

.formatted-dialog-modal-title {
  display: flex;
  flex-direction: row;

  .formatted-dialog-title-text {
    display: block;
    margin-left: 0.25em;
    margin-top: 1px;
  }
}
