.cem-info-modal-content {

    .cem-info-loading-icon {
        padding-top: 50px;
    }

    .name-and-action-content {
        padding-top: 30px;
        font-size: 16px;
        padding-left: 18px;
    }

    .status-and-last-login-content {
        padding-top: 2px;
        font-size: 16px;
        padding-left: 18px;
    }
}