/*
  NOTE: Because the Popup does not actually render itself within the 
   containing div of the Modal control, it cannot be embedded inside a
   Modal css class.  These definitions must be outside.
*/
@import '../../ui-kit/sass/abstracts/_variables.scss';
@import '../../ui-kit/sass/vendor/dx/cui.theme.dx.blue.light.vars.scss';

.dx-overlay-content .dx-popup-content {
  // box-sizing: border-box;
}

#modal-content-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  #modal-main-content {
    flex: 1 1 auto;
    margin-bottom: 10px;
    border-bottom: 1px solid $cui-color-border-light;
    overflow-y: hidden;
  }

  #modal-control-bar {
    display: flex;
    flex-direction: row;
    align-self: flex-end;
  }
}

.modal-header {
  display: flex;
  flex-direction: row;
  font-size: 1.5em;

  margin-top: 0;
  margin-bottom: 0;

  .modal-header-title {
    flex: 1 1 auto;
    font-weight: bolder;

    margin-top: 0.5em;
    margin-bottom: 0.5em;

   // The below code is to make the title text to be ellipsis at the end when it is too long
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 98%;
  }

  .modal-close-button-container {
    display: flex;
    flex-direction: row;
    align-self: center;
    font-weight: bolder;
    margin-right: 0.2em;
    margin-top: auto;
    margin-bottom: auto;
    padding: 0;

    padding-right: 0;
    margin-right: 0;

    .modal-close-button {
      display: flex;
      width: 35px;
      height: 35px;
      box-shadow: 0 1px 3px rgb(0 0 0 / 24%);
      border-radius: 2px;
      cursor: pointer;

      margin-right: 0;

      align-content: center;
      justify-content: center;

      &:hover {
        background-color: $button-normal-hover-bg;
      }

      > * {
        align-self: center;
        justify-self: center;
      }
    }

    .dx-button {
      font-weight: bold;
      font-size: 1.5em;
      height: 40px;
      width: 40px;
    }
  }
}
.modal-title-alignment {
  position: relative;
  padding-top: 20px;
}
.modal-spinner-alignment {
  margin-left: 10px;
  position: relative;
  display: inline;
  top: -8px;
}
.modal-spinner-message-alignment {
  position: relative;
  padding-top: 8px;
}
.ModalConfirmation {
  .files-list {
    margin-top: 2em;
    font-weight: bold;
    margin-bottom: 2em;
  }
  .modal-title {
    font-size: 1em;
    margin-right: 10px;
    vertical-align: text-top;
  }
  .form-scroll-horizontal {
    overflow-x: hidden;
  }
}
.modal-title-parent-div {
  font-size: 1.5em;
}
.button-functions {
  display: flex;
  flex-direction: row;
}
.left-aligned-buttons {
  align-self: flex-start;
}
.right-aligned-buttons {
  align-self: flex-end;
  margin-left: auto;
}
