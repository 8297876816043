.preview-email-modal {
  .back-to-edit-btn {
    background-color: #009cde;
    color: #fff;
  }

  //spacing <p> lines in htmleditor
  .preview-htmleditor {
    .dx-htmleditor-content p {
      margin-bottom: 10px;
    }
  }
    
  .dx-layout-manager .dx-field-item:not(.dx-first-row){
    padding-bottom: 0px;
    padding-top: 0px;
  }
}
