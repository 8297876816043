.ip-table {
  .child-file-toolbar {
    padding-left: 12px;
    padding-right: 20px;
    padding-top: 5px;
  }

  .file-status-indicator {
    margin-top: 7px;
    padding-left: 25px;
  }
  .preview-btn {
    height: 20px;
    width: 20px;
  }
  .downloaded-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}