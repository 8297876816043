.relink-title-parent-div {
  font-size: 1em;

  .relink-title-span {
    margin-right: 5px;
    vertical-align: text-top;
    margin-left: -47px;
  }
}

.files-list {
  margin-top: 2em;
  font-weight: bold;
}

.relink-child-file-container {
  #modal-main-content {
    display: flex;
    flex-direction: column;
  }

  .scroll-container {
    height: auto;
  }

  .confirmation-message{
    margin-top: 20px;
  }
}
