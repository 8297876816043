.manage-existing-investors {
    margin-top: 20px;
  
    .email-textbox {
      min-width: 300px;
    }
  
    .search-container {
      display: flex;
      flex-direction: row;
  
      .clear-button {
        padding-right: 15px;
      }
  
      .search-box {
        flex: 1 1 auto;
        border-left-color: #B3AFAF;
        border-top-color: #B3AFAF;
        display: flex;
  
        .dx-texteditor-container {
          border-left-color: white;
          border-top-color: white;
  
          .dx-texteditor-input-container > .dx-texteditor-input {
            padding-left: 0px;
          }
        }
  
        .dx-button-normal {
          border: none;
          color: #949494;
        }
  
        .dx-button-has-icon .dx-icon {
          font-size: 15px;
          padding-top: 5px;
        }
  
        .icon-button {
          cursor: default;
        }
  
        .dx-icon-clear {
          color: red;
        }
      }
  
      .dx-state-hover {
        .dx-button-content {
          background-color: #F1F1F1;
        }
  
        .dx-texteditor-container {
          border-left-color: #F1F1F1;
          border-top-color: #F1F1F1;
        }
      }
    }
  
    .spaced-content {
      >* {
        margin-right: 20px;
      }
    }
  
    .padded-sides {
      padding-left: 16px;
      padding-right: 16px;
    }
  
    .margin-top {
      margin-top: 16px;
    }
  
    .dx-button-text {
      display: inline;
      vertical-align: middle;
      line-height: 17px;
      text-transform: none;
      font-weight: 500;
      letter-spacing: 0.04em;
    }
  
    .instruction-list {
      max-width: 450px;
      font-size: 15px;
    }
  
    .form-width {
      width: 900px;
      position: relative;
    }
  
    .col-width {
      min-width: 300px;
    }
  
    .button-width {
      width: 250px;
    }
  
    .dx-button-item {
      margin-top: 8px;
    }  

    .provision-button {
        padding-top: 35px;
    }
  
    .dx-texteditor.dx-editor-filled {
      border-bottom-color: transparent;
      border-right-color: transparent;
    }
    .checkbox-icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }