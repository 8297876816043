@import '../../common';

.landing-page-container {
  @include annotatedSelectText(view-selector, 'Current View:', 12%);

  .landing-page-banner {
    max-width: 12%;
    margin-left: auto;
  }
}
