.add-edit-entity-modal {
  .top-align {
    padding-top: 5px;
    width: 99%;
  }

  .tab-active {
    display: block;
  }

  .tab-inactive {
    display: none;
  }

  .required-mark {
    color: red;
    font-size: medium;
    font-weight: bold;
  }

  .header-font {
    font-weight: bold;
    font-size: 15px;
  }

  .row-spacing {
    padding-top: 10px;
  }

  .extra-row-spacing {
    padding-top: 14px;
  }
  .row-bottom-spacing {
    padding-bottom: 10px;
  }
  /* Tag box remove icon display fix */
  .dx-tag-remove-button::after {
    display: none;
  }

  .dx-tagbox .dx-texteditor-container {
    overflow-y: auto;
    max-height: 62px;
  }

  .dx-invalid-message.dx-overlay-wrapper {
    width: 100% !important;
  }

  /* data grid trash icon color change to black */
  .dx-command-edit-with-icons > .dx-link-delete {
    color: black;
  }

  .emails-data-grid {
    padding-top: 8px;
  }

  .add-button-grid {
    margin-top: 5px;
  }

  .dx-texteditor.dx-editor-filled.dx-state-disabled {
    opacity: 1;
  }

  .custom-validation-summary {
    padding-top: 4px;
  }

  .error-display {
    color: red;
    font-weight: bold;
  }
}

#modal-main-content {
  overflow-x: hidden;
}
