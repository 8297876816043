.banner-container-wrapper {
  box-shadow: 0 0 15px var(--cui-color-shadow-base);
  clip-path: inset(0px 0px -15px 0px);
  min-height: 50px;
  padding: 0px 31px 0px;
  margin: -5px -5px -5px;
  display: flex;
  // align-items: center;

  >*:first-child {
    flex: 1 1 auto;
  }

  >*:not(:last-child) {
    border-right: 1px solid var(--cui-color-border-base);
  }

  >* {
    padding: 8px;
  }

  .align-banner {
    display: flex;
  }

  .banner-header-full-width {
    width: 100%;
  }

  .banner-heading {
    font-weight: 400;
    font-size: 1.17em;
    color: var(--cui-color-primary);
    margin: 0 0 -2px 0;
    padding: 0;
  }

  &.single-title {
    padding-top: 0.5em;
  }

  label {
    color: var(--cui-color-base);
    font-size: .75rem;
  }
}