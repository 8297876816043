.investor-election-page-banner {
    justify-content: flex-end;
  .tax-year-dropdown {
    padding-left: 35px;
    max-width: 220px;
  }
  .welcome-text-and-tax-year-dropdown-alignment{
    display: flex;
    justify-content: space-between;
  }
  .cutoff-date{
    padding-left: 30px;
  }
}