@mixin annotatedSelectText($id, $labelContent, $maxWidth) {

  /** Wrap a DevExtreme SelectBox with this, and in another CSS definition,
     define the `content` property of the `dx-texteditor-container::before` class to
     insert text before the select control's selected content.
     
     This will add a new class with the prefix "annotated-select-text-" appended to the specified ID.
     */
  .#{$id} {
    max-width: $maxWidth;

    .dx-texteditor-container::before {
      padding: 0;
      padding-top: 9px !important;
      padding-left: 5px;
      white-space: nowrap;
      content: $labelContent
    }

    .dx-texteditor-input {
      padding-left: 5px !important;
    }
  }
}