.investor-no-elections-submitted-container {
    margin-top: 5px;
    margin-left: 8px;

    .contact-administration-button {
        padding-left: 15px;
    }

    // this class overrides default devextreme css for button
    .dx-button-text {
        text-transform: unset !important;
    }

    .back-button {
        padding-top: 15px;
        padding-left: 10px
    }

    .state-overview-text {
        font-size: 19px;
        padding-left: 10px;
    }

    .sub-header-text {
        font-size: 19px;
        padding-left: 10px;
        margin-bottom: 10px;
    }

    .page-header-items {
        display: flex;
    }

    .sub-header-grid {
        padding-bottom: 10px;
    }

    .elections-not-submitted-message {
        padding-left: 10px;
    }
}