.download-report-status {
    .status-icon-success {
        color: #009CDE;
        padding-right: 5px;
    }

    .status-icon-error {
        color: #F70000;
        padding-right: 5px;
    }

    .message-container {
        padding-top: 3px;
        display: flex;
    }
}