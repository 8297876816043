.investor-state-selections {
  .field-label {
    font-size: 16px;
    cursor: default;
    align-self: center;
    padding-right: 12px;
    padding-left: 12px;
    padding-top: 8px;
  }
  .align-icon {
    padding-left: 5px;
  }
  .content-area {
    display: flex;
    .header-display {
      font-family: 'Prelo-Bold';
    }
    .col {
      padding: 10px;
      flex: 45%;
      align-items: stretch;
    }
    .align-tagbox {
      padding-top: 8px;
    }
    .tag-box-enabled {
      .dx-tagbox .dx-texteditor-container {
        overflow-y: auto;
        max-height: 180px;
        min-height: 155px;
      }
    }
    .tag-box-disabled {
      .dx-tagbox .dx-texteditor-container {
        overflow-y: auto;
        min-height: 155px;
      }
    }
  }
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 60%;
    left: 40%;
    font-size: xx-large;
  }

  .align-icon {
    padding-left: 5px;
  }
}
