.chart-container {
  justify-content: space-evenly;
  align-items: center;
  display: grid;
  align-content: center;
  align-items: center;
  width: 100%;

  .bar-chart-container {
    .before-bar {
      display: flex;
      justify-content: flex-end;
      .textdisplay {
        padding: 10px;
        font-size: 17px;
        font-weight: bold;
      }
    }
    .bar-chart {
      height: 60px;
      width: 100%;
    }
    .after-chart {
      margin-bottom: 10px;
      .valuedisplay {
        padding: 8px;
        font-size: 17px;
        font-weight: bold;
      }
    }
  }
}
