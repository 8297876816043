.investor-client-group-tile {
  .client-group-image {
    cursor: default;
  }
  .placeholder-align-right {
    text-align: right;
  }
  .placeholder-align-left {
    text-align: left;
  }
  .cui-c_card:not(:last-child) {
    border-right: 0px;
  }
  .navigate-font {
    color: black;
    padding-right: 5px;
  }
}
