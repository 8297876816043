.ip-table {
    .parent-file-toolbar {
        padding-top: 6px;

        .document-count-style {
            padding-top: 5px;
            font-size: 13px;
            padding-left: 20px;
        }

        .parent-file-actionbtn {
            padding-left: 40px;
        }
    }

    .preview-btn {
        height: 20px;
        width: 20px;
    }

    .parent-info-icon-style {
        font-size: 20px !important;
        color: #5A5A5A !important;
        vertical-align: sub !important;
        margin-right: 5px !important;
    }
}

// This styling keeping outside because action menu controls are appearing outside of component
.parent-menu-items {
    .menu-items-pointer {
        pointer-events: auto;
    }
}