.cui-c_box {
    &-header {
        min-height:34px;
        display:flex;
        align-items:center;
        border-bottom:1px solid var(--cui-color-border-dark);
        &_title {
            font-size:1rem;            
            margin-right:auto;
            a{
                text-decoration:none;
                color: var(--cui-color-primary);
            }
        }
        &_actions {
            color: var(--cui-color-muted);
        }

    }
    &-body {
        border:1px solid transparent;
        border-radius:3px;
        padding:$layout-gap 0;
    }

}
