/// Mixin to manage responsive breakpoints
/// @author Kitty Giraudel
/// @param {String} $cui-breakpoint - Breakpoint name
/// @require $cui-breakpoints
@mixin respond-to($cui-breakpoint) {
    // If the key exists in the map
    @if map-has-key($cui-breakpoints, $cui-breakpoint) {
      // Prints a media query based on the value
      @media (min-width: map-get($cui-breakpoints, $cui-breakpoint)) {
        @content;
      }
    }
   
    // If the key doesn't exist in the map
    @else {
      @warn "Unfortunately, no value could be retrieved from `#{$cui-breakpoint}`. "
          + "Available breakpoints are: #{map-keys($cui-breakpoints)}.";
    }
  }