
.cui-c_card {
    padding: $layout-gap 0;
    flex-grow:1;
    text-decoration:none;
    
    &-group {
        display:flex;
        flex-direction: row;
        [class*="card"]{
            padding: $layout-gap;
        }
    }

    &-animated {
        transition: transform 150ms linear;
        &:hover {
            transform: scale(1.25);
            border:None;
            background:var(--cui-color-bg-base);
            box-shadow: 0 0 10px var(--cui-color-shadow-base);
        }
    }

    &-description{
        white-space:normal;
        display:flex;
        align-items: center;
        height:80px;
        p{
            margin:0;
        }
    }

    &-actions {
        flex-grow: 0;
        display:flex;
        align-items:center;
        justify-content: center;
    }

    &:not(:last-child) {
        border-right: 1px solid var(--cui-color-border-base);
    }

    h3{    
        font-weight:normal;
        color: var(--cui-color-primary);
        margin:0 0 -2px 0;
        padding:0;
    }
    label {
        color: var(--cui-color-base);
        font-size:.75rem;
    }
}
