.cui-c_modal {
  &-title {
    font-size: 1.4rem;
    border-bottom: 1px solid var(--cui-color-border-base);
    padding: 0 $layout-gap * 2;
    line-height: $layout-dx;
    color: var(--cui-color-tertiary);
  }
  &-body {
    font-size: 1rem;
    padding: $layout-gap * 2 !important;
    min-height: 100px;
  }
  &-footer {
    min-height: $layout-gap;
    padding: $layout-gap * 2 !important;
    background: var(--cui-color-bg-light);
  }
}
