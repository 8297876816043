.header_dropdown {
  .icon-alignment {
    top: 7px;
    position: relative;
    margin-right: 10px;
  }
  .header-ul {
    max-width: 300px !important;
    a {
      text-decoration: none;
      display: block;
      padding: 0 16px;
      line-height: 42px !important;
      color: var(--cui-color-primary) !important;
      white-space: nowrap !important;
    }
    .div-link {
      text-decoration: none;
      display: block;
      padding: 0 16px;
      line-height: 42px !important;
      color: var(--cui-color-primary) !important;
      white-space: nowrap !important;
      cursor: pointer;
    }
  }
}
