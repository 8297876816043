.allocating-entity-selections {
  .field-label {
    font-size: 16px;
    cursor: default;
    align-self: center;
    padding-right: 12px;
    padding-left: 12px;
    padding-top: 8px;
  }

  .align-icon {
    padding-left: 5px;
  }

  .header-display {
    font-size: medium;
    font-weight: bold;
  }

  .content-area {
    display: flex;

    .col {
      padding: 10px;
      flex: 45%;
      align-items: stretch;
    }

    .align-tagbox {
      padding-top: 2px;
    }

    .dx-tagbox .dx-texteditor-container {
      overflow-y: auto;
      min-height: 155px;

      .dx-texteditor-input-container {
        display: block;
      }
    }
  }

  .reporttype-content-area {
    padding-left: 11px;
    display: flex;
    flex-direction: column;
    .report-types {
      margin-top: 5px;
      display: flex;
      flex-direction: column;
    }

    .row {
      display: flex;
      flex-direction: row;
    }

    .col {
      flex: 0.5;
      margin-right: 10px;
      padding: 5px;
    }
  }

  margin-top: 5px;

  .entity-tab-buttons {
    float: right;
    align-items: flex-end;
    margin-top: 5px;
  }

  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 60%;
    left: 40%;
    font-size: xx-large;
  }

  .align-icon {
    padding-left: 5px;
  }

  .all-reports-box {
    padding-top: 3px;
  }

  .download-report-button {
    margin-left: 5px;
  }

  .dx-tag-remove-button::after {
    display: none;
  }

  .dx-state-disabled.dx-widget {
    opacity: 5.38;
  }

  .no-election-flex {
    display: flex;
  }

  .no-election-margin {
    margin-left: 30px;
    margin-top: -7px;
  }
}
