.entity-list-page-banner {
    display: flex;

    banner-container-wrapper .banner-header-full-width {
        width: 60%;
    }

    .current-view-control {
        margin-right: 10px;
        max-width: 180px;
    }
}