.manage-settings-container {
  .dx-tab-text {
    // override the css to display tab text as normal (not in Uppercase)
    text-transform: none !important;
  }
  .useglobal-state-box {
    margin-left: 20px;
    margin-top: 15px;
    margin-bottom: 12px;

    .dx-checkbox-text {
      margin-left: 21px;
    }
  }
  .investor-settings-chkbox {
    padding-top: 7px;
    margin-left: 15px;
    padding-bottom: 5px;
    .use-global-settings-chkbox {
      margin-left: 20px;
    }
  }
}
