.button-clear-filters {
  color: var(--cui-color-primary);
}

.button-cursor-no-filter-applied {
  cursor: default;
  padding-left: 15px;
  padding-right: 15px;
}

.button-cursor-clear-filter {
  cursor: pointer;
}