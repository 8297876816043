    .bulk-upload-file {
        .row-spacing {
            margin-top: 20px;
            padding-left: 7px;
        }

        .cancel-btn {
            margin-right: 15px;
        }

        .download-error-sheet-link {
            margin-top: 29px;
        }
    }

    .bulk-upload-error-model {
        .download-link-area {
            padding-top: 25px;
        }
    }