@import '../../ui-kit/sass/abstracts/variables';

.ip-table {
  /** Styles for the data grid div content  */
  padding: $layout-gap * 2;

  /** Styles for the grid header panel or grid toolbar section */
  .dx-datagrid-header-panel {
    padding: 0;
  }

  /** Styles for the grid header row  */
  .dx-datagrid-headers .dx-datagrid-table .dx-row > td {
    font-weight: bold;
  }

  /** Styles for the data grid header row and column*/
  .dx-datagrid-headers .dx-datagrid-table .dx-row > td {
    line-height: 30px;
  }

  /** Styles for the header row of the data grid*/
  .dx-header-row {
    // background-color: $cui-color-bg-base;
  }

  /** Styles for the data grid */
  .dx-datagrid {
    background: #fff;
  }

  /** Styles for the data grid rows and columns*/
  .dx-datagrid .dx-row > td {
    line-height: 30px;
  }

  /** Styles for the data grid rows (only data filled rows) */
  .dx-data-row {
    background-color: $cui-color-bg-base;
  }

  /** Stytles for the data row when checkbox is selected on the grid */
  .dx-datagrid-rowsview
    .dx-selection.dx-row:not(.dx-row-focused).dx-row-lines
    > td,
  .dx-datagrid-rowsview
    .dx-selection.dx-row:not(.dx-row-focused):hover.dx-row-lines
    > td {
    background-color: $cui-color-bg-base;
  }

  /** Styles for the rows height or padding style on the grid  */
  .dx-datagrid .dx-row > td {
    padding: 5px 11px;
  }

  /** Styles for the header row checkbox when any of the data row is selected*/
  .dx-checkbox-indeterminate .dx-checkbox-icon {
    background-color: $cui-color-bg-base;
    border: 2px solid $cui-color-border-dark;
  }

  /** Styles for the checkbox background color when it is selected on the grid */
  .dx-datagrid-checkbox-size.dx-checkbox-checked .dx-checkbox-icon {
    background-color: $cui-color-blue;
  }

  /** Styles for the search panel text editor */
  .dx-texteditor.dx-editor-filled {
    border: 1px solid var(--cui-color-bg-dark);
    height: 2rem;
  }

  /** Styles for the search panel text editor when not focused */
  .dx-texteditor.dx-editor-filled::after {
    border-bottom: 0px;
  }

  /** Styles for the search panel text editor when focused or typing the text*/
  .dx-texteditor.dx-state-active::before,
  .dx-texteditor.dx-state-focused::before {
    border-bottom: 0px;
  }

  /** Styles for the search panel text editor when focused or typing the text*/
  .dx-texteditor.dx-state-focused.dx-editor-filled {
    border-color: $cui-color-blue;
  }

  /** Styles for the search panel text editor when typed any text*/
  .dx-show-clear-button .dx-icon-clear {
    color: $cui-color-red;
  }

  /** Styles for highlighting the matched search text in the datagrid */
  .dx-datagrid-search-text {
    background-color: $cui-color-blue;
  }

  /** Display box on files count*/
 .counter-display-title{
 font-size: 16px;
  .count-border {
    width: 8px;
    height: 8px;
    border: 1px solid $cui-color-blue;
    padding: 2px;
    margin-left: 8px;
  }
 } 

  /**datagrid toolbar button styles */
  Button {
    color: var(--cui-color-base);
    border-color: var(--cui-color-bg-dark);
    min-width: 2rem;
    min-height: 2rem;
  }

  .error {
    color: red;
  }

  .statusColumnGrey {
    background-color: $cui-color-bg-light;
    padding: 3px 8px;
    border-radius: 25px;
  }

  .statusColumnBlue {
    background-color: rgb(211, 238, 248);
    padding: 3px 8px;
    border-radius: 25px;
  }

  .statusColumnGreen {
    background-color: rgb(217, 241, 217);
    padding: 3px 8px;
    border-radius: 25px;
  }
  
  .statusColumnRed {
    background-color: rgb(253 117 117);
    padding: 3px 8px;
    border-radius: 25px;
  }

  .publishButton {
    top: -10px;
    position: relative;
    padding-left: 3px;
  }

  .publishIcon {
    top: -2px;
    position: relative;
  }
}

.dx-popup-normal {
  [aria-label='OK'] {
    border-radius: 2px;
    background-color: #009cde;
    color: #fff;
    box-shadow: 0 1px 3px rgb(0 0 0 / 24%);
  }

  [aria-label='OK']:hover {
    background-color: #007eb3;
    box-shadow: 0 1px 3px rgb(0 0 0 / 24%);
  }

  [aria-label='Cancel'] {
    height: 28px;
    position: relative;
    overflow: hidden;
    border-radius: 2px;
    background-color: #fff;
    color: #000;
    box-shadow: 0 1px 3px rgb(0 0 0 / 24%);
  }

  .dx-texteditor.dx-editor-filled {
    background-color: #fff;
  }

  .dx-texteditor.dx-state-focused.dx-editor-filled {
    border-color: #fff;
  }
}
