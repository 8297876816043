.scrollable-message-container {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  height: 100%;
  padding-bottom: 1.5em;

  > * {
    width: 100%;
  }

  .scroll-view {
    height: auto;
  }

  .static-content-container {
    margin-top: 20px;
    flex: 1 1 auto;
    height: auto !important;
  }
}
