.dms-file-location {
    padding-top: 19px;

    .select-box-padding {
        padding-top: 2px;
        padding-bottom: 10px;
    }

    .header-font {
        padding-left: 8px;

    }

    .MuiGrid-container>.MuiGrid-item {
        padding-top: 0px
    }

    // As of now not using but keeping it for future reference
    .required-mark {
        color: red;
        font-size: medium;
        font-weight: bold;
    }
}