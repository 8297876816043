.cui-c_masthead {    
    background-color: rgba(var(--cui-color-primary-rgb), .1);
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 2.7rem), 0 100%);
    padding-bottom:$layout-gap*3;
    &-wrapper {
        
        background-color: rgba(var(--cui-color-primary-rgb), .1);
        clip-path: polygon(0 0, 100% 0, 100% calc(100% - 1.7rem), 0 100%);
        padding-top: $layout-gap * 2;
        padding-bottom: $layout-gap * 2;
        align-items:center;
        position:relative;
    }
    &-container {
        min-height:200px;
        position:relative;
        display:flex !important;
        align-items:center;
    }

    &-image {
        &_box {
            position:absolute;    
            bottom:$layout-gap * 2 *-1;
            right:$layout-gap * 4;

        }
    }
    //
    h1 {
        margin:0;
        font-size:2.4rem;
        font-family:"Prelo-Book";
        font-weight: 300;
        margin-bottom:$layout-gap * 2;
        color: var(--cui-color-primary);
    }
    p {
        margin:0;
        margin-bottom:$layout-gap * 2;
        color: var(--cui-color-base);
        font-size: 13px;

    }

}