.cui-header {
    height: $layout-dx;
    background-color: var(--cui-color-primary);
    display:flex;
    align-items: center;
    z-index:10;
    position:sticky;
    top:0;
    .page-header_app {
        display:flex;    
    }                
    
    .page-header_app-name {
        height: $layout-dx;
        //background: var(--cui-color-bg-base);
        font-family: var(--cui-font-brand-light);
        //color: var(--cui-color-secondary);
        color: #fff;
        font-size:1.4rem;
        display:flex;
        align-items:center;
        padding:0 $layout-gap * 4;
        letter-spacing: .02em;
        > span:first-child {
            padding-right:$layout-gap;
        }
        b {
            font-weight:800;
            font-family:var(--cui-font-brand-bold);
        }
        img{
            margin-top:5px;
        }
        &_client {
            img {
                max-height: $layout-dx - $layout-gap*3;
                max-width:240px;
            }
        }
    }


    .page-header_meta {
        margin-left:auto;
        display:flex;
        &-actions {
            display:flex;
            align-items: center;
            a {
                color:#fff;
                margin: $layout-gap;
            }
        }
    }
    
}