// .cui-c_iconography {
//     width:4rem;
//     height:4rem;
//     background: var(--cui-color-muted);
//     position:relative;
//     &-pie {
//         position:absolute;
//         bottom:5%;
//         right:5%;
//         width: 3rem;
//         height: 3rem;
//         background:var(--cui-color-primary);    
//         border-top-left-radius: 90%;
//         border-top-right-radius: 10%;
//         border-bottom-left-radius: 10%;
//         border-bottom-right-radius: 10%;
//         b{
//             position:absolute;
//             width:100%;
//             text-align:center;
//             color:#fff;
//             font-family:'prelo-bold';
//             font-size: 3rem;
//         }
//     }
// }

.cui-c_iconography {
    width: $layout-dx;
    height:$layout-dx;
    background: var(--cui-color-primary);
    position:relative;
    &-id {
        position:absolute;
        height: $layout-dx - $layout-gap*2;
        width: $layout-dx - $layout-gap*2;
        background: rgb(136,139,141);
        background: linear-gradient(25deg, rgba(136,139,141,1) 0%, rgba(183,185,186,1) 100%);
        border-top-left-radius: 10%;
        border-top-right-radius: 20%;
        border-bottom-left-radius: 10%;
        border-bottom-right-radius: 5%;
        top:10%;
        left:10%;
        color: #E7E7E8;
        > span {
            font-size:1.7rem;
            margin-top:.1rem;
            margin-left:.2rem;
        }
    }
    &-pie {
        position:absolute;
        bottom:10%;
        right:10%;
        width: 60%;
        height: 60%;
        background:var(--cui-color-primary);   
        background: linear-gradient(315deg, rgba(var(--cui-color-ico-base),1) 0%, rgba(var(--cui-color-ico-light),1) 100%); 
        border-top-left-radius: 90%;
        border-top-right-radius: 10%;
        border-bottom-left-radius: 10%;
        border-bottom-right-radius: 10%;
        box-shadow:0 0 .5em rgba(0,0,0, .3);
        i,
        b{
            position:absolute;
            bottom:0;
            right:20%;
            text-align:right;
            color:#fff;
            font-family:'prelo-bold-italic';
            font-size: 1rem;
            font-style:italic;
            &::first-letter {
                font-size:1.4rem;
            }
        }
    }
}