.entitymanager-table {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  text-align: center;
  .count-display {
    padding-block: 5px;
  }
  .empty-associated-entities {
    color: gray;
  }
  .loadapi-investor-status {
    position: absolute;
  }
}
