
.cui-c_feed {
    &-item {
        &:not(:last-child){
            border-bottom:1px solid var(--cui-color-border-base);
        }
        margin-top: $layout-gap*2;
        padding-bottom:$layout-gap;
    }
    &-header {
        display:flex;
    }
    &-title {
        font-size:.85rem;
        color: var(--cui-color-primary);
        margin-bottom:$layout-gap;
        line-height:1rem;        
        
        a{
            text-decoration:none;
        }
    }    
    &-body {
        font-size:.8rem;
        line-height:.9rem;
        margin-bottom: $layout-gap;
    }

    &-footer {
        color: var(--cui-color-muted);
        font-size:.7rem;
    }
}