.cui-c_drawer {
    &-container {
        width: 560px;
        display:flex;
        flex-direction: column;
        height: 100%;
        overflow:hidden;
    }
    
    &-header {
        display:flex;
        padding: 0 $layout-gap * 2;
        height: $layout-dx;
        align-items: center;
        flex-shrink:0;
        border-bottom:1px solid var(--cui-color-border-base);
        button {
            min-width:auto;
        }
        h4 {
            margin:0;
            margin-right:auto;
        }
    }

    &-body {
        flex-grow:1;
        overflow:auto;
        padding: $layout-gap * 2;
    }

    &-footer {        
        background: var(--cui-color-bg-light);
        padding: $layout-gap * 2;
        text-align:right;
    }    
}