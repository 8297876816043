.investor-details-page-container {
    margin-right: 35px;
    height: calc(87vh - 200px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 3px;
    padding-left: 9px;
    padding-right: 8px;

    .grid-border {
        border: 2px solid #F1F1F1;
        margin-top: 5px;
    }

    .bottom-border-style {
        border-bottom: 2px solid #F1F1F1;
    }
}