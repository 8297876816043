@import '../../common';

.client-group-details-banner {
  // Adds the "Current View" label to the select box in the "Current View" dropdown.
  //  Creates a current-view style as well.
  @include annotatedSelectText(current-view, 'Current View:', 220px);

  .current-view-control-spacing {
    padding-top: 1px;
    padding-left: 10px;
    padding-right: 25px;
  }
}