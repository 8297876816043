.landing-page-container {
  .tile-placeholder-image {
    font-size: 120px;
    color: #dedede;
  }

  .align-center {
    text-align: center;
    display: block;
  }

  .placeholder-align-center {
    text-align: center;
  }

  .button-learn {
    display: none;
  }

  .favorite-icon {
    cursor: pointer;
    color: #009cde;
  }

  .button-align {
    font-size: 12px;
    left: -10px;
  }

  .button-font {
    font-size: 12px;
    margin-left: 4px;
  }

  .dx-datagrid-header-panel {
    padding-right: 0;
    margin: 0;
    border-bottom: 0;
  }

  .align-grid-left {
    padding-left: 5%;
  }

  .link-button {
    text-transform: none;
  }
}
