.edit-email-modal {
  .preview-mail-btn {
    margin-right: 119px;
    width: 144px;
  }

  .cancel-btn {
    width: 95px;
    margin-right: 4px;
    flex-grow: 1;
  }

  .delete-cust-btn {
    width: 210px;
    margin-right: 4px;
    flex-grow: 1;
  }

  .save-btn {
    width: 90px;
    flex-grow: 1;
  }

  .required:after {
    content: ' *';
    color: red;
  }

  //spacing <p> lines in htmleditor
  .dx-htmleditor-content p {
    margin-bottom: 10px;
  }

  #modal-control-bar {
    width: 100% !important;
  }

  #modal-main-content {
    padding-bottom: 15px;
  }
}