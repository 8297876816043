#cui-layout_leftnav .cui-nav .nav-list {
  &.nav-list_lvl-two li {

    .nav-link {
      padding: 0.5rem;
      display: flex;
      vertical-align: middle;

      cursor: pointer;

      >span:first-child {
        padding-left: 1rem;
      }

      span[class*='icons'] {
        font-size: 1rem;
        width: 1rem;
      }

      .nav-name {
        display: inline-block;
        padding-left: 0.5rem;
      }
    }
  }

  // Taken from _nav.scss:120 and changed to use a div
  //  since the a tag should not be used due to standardization reasons.
  .nav-link {
    cursor: pointer;
    text-decoration: none;
    color: #fff;
    //width: $layout-dx;
    height: 56px; // Taken from _variables.scss:73
    display: flex;
    align-items: center;
    //justify-content: center;
    position: relative;

    span[class*="icons"] {
      width: 56px; // Taken from _variables.scss:73
      text-align: center;
    }

    .nav-name {
      font-size: .85rem;
    }

    color: rgba(var(--cui-color-base-rgb), .65);
    transition: color 200ms linear;

    &:hover {
      color: rgba(var(--cui-color-base-rgb), 1)
    }

  }

  // This overrides the nav-link above for the second level text in the menu.
  .nav-list.nav-list_lvl-two .nav-link {
    height: auto;
  }
}