.ip-table {
.status-button-container {
    max-width: 40px
}
.status-label-container {
    margin-top: 5.7px;
    .status-label{
        font-size: 16px;
    }
}
.dx-light-pages {
    display: none !important
}
}