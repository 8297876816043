.cui-c_list {
    margin-top:.25rem;
    padding:0;
    margin:0;
    list-style:none;
    &-inline {
        li {
            display:inline-block;
            margin-right:1rem;        
        }
    }
}