@import '../../_variables.scss';

.sm-investor-selector-container {
  .ip-table {
    padding-top: 0px;
  }
  .toolbar-investortable {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 10px;
    margin-top: -20px;

    .before-search {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }

    .search-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      .clear-button {
        display: flex;
        justify-content: flex-end;
        padding-right: 15px;
      }

      .search-box-container {
        justify-content: flex-start;
        display: flex;

        .search-box {
          flex: 1 1 auto;
          border-left-color: #b3afaf;
          border-top-color: #b3afaf;
          display: flex;

          .dx-texteditor-container {
            border-left-color: white;
            border-top-color: white;

            .dx-texteditor-input-container > .dx-texteditor-input {
              padding-left: 0px;
            }
          }

          .dx-button-normal {
            border: none;
            color: #949494;
          }

          .dx-button-has-icon .dx-icon {
            font-size: 15px;
            padding-top: 3px;
          }

          .icon-button {
            cursor: default;
          }

          .dx-icon-clear {
            color: red;
          }
        }

        .dx-state-hover {
          .dx-button-content {
            background-color: #f1f1f1;
          }

          .dx-texteditor-container {
            border-left-color: #f1f1f1;
            border-top-color: #f1f1f1;
          }
        }
      }

      .after-search {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .status-buttons-incomplete {
    border-radius: 15px;
    color: $status-buttons-incomplete-color;
    border: 1px solid $status-buttons-incomplete-color;
  }

  .status-buttons-incomplete:hover {
    border-radius: 15px;
    color: $status-buttons-incomplete-color;
    border: 1px solid $status-buttons-incomplete-color;
    background-color: $status-buttons-complete-background-color;
  }

  .status-buttons-complete {
    border-radius: 15px;
    color: $status-buttons-complete-color;
  }

  .popup-tooltip {
    display: block;
  }

  .button-like-div {
    display: inline-block;
    padding: 8px 16px;
    background-color: #f6f4f4;
    border: black;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    transition-duration: 0.3s;
  }
}
