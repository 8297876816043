.state-selector {
  max-width: 99%;
  max-height: 80%;
  margin-top: 2px;
  margin-bottom: 2px;

  .stateselector-datagrid {
    margin-bottom: 4px;
    height: 80%;
    max-width: 100%;

    .dx-datagrid-header-panel {
      margin-right: -9px;
      border: 1px solid #e7e7e8;
      padding: 0;
      margin: 0;
      border-bottom: 0;
    }
  }

  .state-selector-buttons {
    margin-top: 4px;
    float: right;

    .dx-widget.dx-button.dx-button-mode-contained.dx-button-default.dx-button-has-text.clear-btn-State.dx-state-disabled {
      opacity: 2.56;
    }

    .save-btn-State {
      margin-left: 5px;
    }
  }

  .select-all-chkbox {
    padding-top: 7px;
    padding-left: 8px;

    .checkbox-container {
      display: flex;
      align-items: center;
      padding-left: 8px;

      .select-all-chkbox-text {
        margin-left: 28px;
      }
    }
  }

  .disabled-checkbox {
    opacity: 0.9;
  }
}
