.help-page-container {
  .item-text-style {
    padding-left: 2%;
  }

  .span-item {
    color: #009cde;
    padding-left: 5px;
    padding-right: 5px;
  }
}
