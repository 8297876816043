$cui-color-blue: #009cde;
$cui-color-green: #3f9c35;
$cui-color-grey: #888b8d;
$cui-color-red: #ff0000;
$cui-color-grey-dark: #63666a;

$cui-border-base: #f1f1f1;

$cui-font-base: Arial !default;
$cui-font-brand-base: Prelo-Medium !default;
$cui-font-brand-light: Prelo-Light !default;
$cui-font-brand-bold: Prelo-Bold !default;

$cui-color-ico-base: #009cde;
$cui-color-ico-light: #33afe4;

$cui-color-base: #000000 !default;
$cui-color-muted: #888b8d !default;

$cui-color-primary: #009cde !default;
$cui-color-secondary: #3f9c35 !default;
$cui-color-tertiary: #63666a !default;

$cui-color-accent: #009cde !default;

$cui-color-border-base: #f1f1f1 !default;
$cui-color-border-light: #63666a !default;
$cui-color-border-dark: #888b8d !default;

$cui-color-bg-base: #fff !default;
$cui-color-bg-light: #f1f1f1 !default;
$cui-color-bg-dark: #b9b9b9 !default;

$cui-color-shadow-base: #aaa !default;
//$cui-color-shadow-light: #efefef !default;

:root {
  --cui-font-base: #{$cui-font-base};
  --cui-font-brand-base: #{$cui-font-brand-base};
  --cui-font-brand-light: #{$cui-font-brand-light};
  --cui-font-brand-bold: #{$cui-font-brand-bold};

  --cui-color-ico-base: #{convertHexToRgb($cui-color-ico-base)};
  --cui-color-ico-light: #{convertHexToRgb($cui-color-ico-light)};

  --cui-color-base: #{$cui-color-base};
  --cui-color-muted: #{$cui-color-muted};
  --cui-color-primary: #{$cui-color-primary};
  --cui-color-secondary: #{$cui-color-secondary};
  --cui-color-tertiary: #{$cui-color-tertiary};

  --cui-color-base-rgb: #{convertHexToRgb($cui-color-base)};
  --cui-color-muted-rgb: #{convertHexToRgb($cui-color-muted)};
  --cui-color-primary-rgb: #{convertHexToRgb($cui-color-primary)};
  --cui-color-secondary-rgb: #{convertHexToRgb($cui-color-secondary)};
  --cui-color-tertiary-rgb: #{convertHexToRgb($cui-color-tertiary)};

  --cui-color-border-base: #{$cui-color-border-base};
  --cui-color-border-light: #{$cui-color-border-light};
  --cui-color-border-dark: #{$cui-color-border-dark};

  --cui-color-bg-base: #{$cui-color-bg-base};
  --cui-color-bg-light: #{$cui-color-bg-light};
  --cui-color-bg-dark: #{$cui-color-bg-dark};

  --cui-color-shadow-base: #{$cui-color-shadow-base};
  //--cui-color-shadow-light:#{$cui-color-shadow-light};
}
//Alpha
$set-alpha-base: 0.8;

//Dimensions
$layout-dx: 56px;
$layout-gap: 8px;

$layout-menu-active: 250px;

//Breakpoints
$cui-breakpoints: (
  'xs': 0,
  'sm': 600px,
  'md': 900px,
  'lg': 1200px,
  'xl': 1536px,
) !default;
